<template>
  <v-card
    class="elevation-0"
    outlined
  >
    <HeaderCard>
      <v-icon
        class="mr-1"
      >
        mdi-music-circle-outline
      </v-icon>
      <b>{{ 'title.newAlbumsLabel'.translate() }}</b>

      <v-spacer />

      <v-btn
        v-if="isAdmin"
        x-small
        outlined
        color="white"
        @click="handleNewAlbumPage"
      >
        <v-icon small>
          mdi-plus
        </v-icon>
        Adicionar
      </v-btn>
    </HeaderCard>

    <v-card-text style="padding: 0;">
      <v-form
        ref="form"
        autocomplete="off"
      >
        <v-container
          grid-list-xl
          class="mx-3"
        >
          <v-layout wrap>
            <v-flex
              xs6
              sm6
              md6
              lg6
            >
              <FilterText
                label="filter.albumName"
                v-model="filters.nome"
              />
            </v-flex>

            <v-flex
              xs6
              sm6
              md6
              lg6
            >
              <FilterText
                label="filter.learnDescriptionArg"
                v-model="filters.descricao"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>

      <v-data-table
        dense
        :headers="headers"
        :items="rows"
        :loading="isLoading"

        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"

        disable-pagination
        hide-default-footer

        class="elevation-0"
        item-key="id"
      >
        <template #item="{ item, index }">
          <AlbumItem
            :is-admin="isAdmin"
            :album="item"
            :odd="index % 2"
          />
        </template>
        <template #footer>
          <v-pagination
            v-model="page"
            :length="Math.ceil(count / 12)"
            :total-visible="10"
            v-if="Math.ceil(count / 12) > 1"
            @input="$vuetify.goTo(0, { duration: 850, offset: 0, easing: 'easeInOutCubic' })"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { getSession } from '@/utils'
import FilterText from '@/components/filters/FilterText'
import HeaderCard from '@/components/HeaderCard'
import AlbumItem from './AlbumItem'
import { mapState } from 'vuex'

import {
  STATUS_LOADING,
  ROUTE_ALBUMS_NEW
} from '@/constants'

const hasQryVal = (qry, val) => qry[val] !== undefined && String(qry[val]).trim()

export default {
  components: {
    FilterText,
    HeaderCard,
    AlbumItem
  },

  data () {
    const qry = { ...this.$route.query }
    const page = isNaN(qry.page) ? 1 : Number(qry.page)
    const sortBy = hasQryVal(qry, 'sortBy') ? qry.sortBy : 'nome'
    const orderDesc = String(qry.order || '').trim() === 'DESC'

    const filters = {
      nome: hasQryVal(qry, 'nome') ? qry.nome : undefined
    }

    const options = {
      sortBy: [sortBy],
      sortDesc: [orderDesc],
      itemsPerPage: 12
    }

    return {
      session: {},
      page,
      options,
      filters,
      albums: []
    }
  },

  mounted () {
    this.loadSession()
    this.findAlbums()
  },

  computed: {
    ...mapState({
      rows: ({ albums }) => albums.rows,
      count: ({ albums }) => albums.count,
      isLoading: ({ albums }) => albums.status === STATUS_LOADING
    }),

    isAdmin () {
      return this.session?.administrador
    },

    headers () {
      return [
        { text: 'Imagem', align: 'start', sortable: false, value: 'imagem', width: '100px' },
        { text: 'Nome', align: 'start', sortable: true, value: 'nome' },
        { text: 'Descrição', align: 'start', sortable: true, value: 'descricao' },
        { text: '', align: 'center', sortable: false, value: '', width: '110px' }
      ]
    }
  },

  methods: {
    loadSession () {
      getSession().then((session) => (Object.assign(this, { session })))
    },

    findAlbums () {
      const query = {
        page: this.page,
        withCount: true,
        order: this.options.sortDesc[0] !== false ? 'DESC' : 'ASC',
        sortBy: this.options.sortBy.length ? this.options.sortBy[0] : 'nome'
      }

      const fltrs = { ...this.filters }
      if (hasQryVal(fltrs, 'nome')) query.nome = fltrs.nome
      if (hasQryVal(fltrs, 'descricao')) query.descricao = fltrs.descricao

      this.$store.dispatch('loadAlbums', query)
    },

    handleNewAlbumPage () {
      this.$router.push({ name: ROUTE_ALBUMS_NEW })
    }
  },

  watch: {
    page () {
      this.findAlbums()
    },

    rows (rows) {
      this.albums = rows
    },

    filters: {
      deep: true,
      handler () {
        this.page = 1
        this.findAlbums()
      }
    },

    options: {
      deep: true,
      handler () {
        this.findAlbums()
      }
    }
  }
}
</script>
